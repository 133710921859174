/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
.cb-layout2 #head,
.cb-layout3 #head {
  width: 83.03571429%;
}
.desk {
  width: 896px !important;
}
.cb-goto-top {
  display: none;
}
.desk > .sub2 {
  width: 15.17857143%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.05263158%;
  margin-left: 1.05263158%;
}
.area .part,
.area > .grid table {
  margin-right: 1.05263158%;
  margin-left: 1.05263158%;
  width: 97.89473684%;
}
.area .tiny {
  width: 47.89473684%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 2.10526316%;
  margin-left: 2.10526316%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 95.78947368%;
}
.area > .slim .tiny {
  width: 95.78947368%;
}
.cb-layout1 .area {
  width: 100%;
}
.cb-layout1 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area h2,
.cb-layout1 .area .foot {
  margin-right: 0.87719298%;
  margin-left: 0.87719298%;
}
.cb-layout1 .area .part,
.cb-layout1 .area > .grid table {
  margin-right: 0.87719298%;
  margin-left: 0.87719298%;
  width: 98.24561404%;
}
.cb-layout1 .area .tiny {
  width: 31.57894737%;
}
.cb-layout1 .area > .slim {
  width: 33.33333333%;
}
.cb-layout1 .area > .slim h2,
.cb-layout1 .area > .slim .foot,
.cb-layout1 .area > .slim .part,
.cb-layout1 .area > .slim.grid table {
  margin-right: 2.63157895%;
  margin-left: 2.63157895%;
}
.cb-layout1 .area > .slim .part,
.cb-layout1 .area > .slim.grid table {
  width: 94.73684211%;
}
.cb-layout1 .area > .slim .tiny {
  width: 94.73684211%;
}
.cb-layout3 .area {
  width: 100%;
}
.cb-layout3 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area h2,
.cb-layout3 .area .foot {
  margin-right: 1.05263158%;
  margin-left: 1.05263158%;
}
.cb-layout3 .area .part,
.cb-layout3 .area > .grid table {
  margin-right: 1.05263158%;
  margin-left: 1.05263158%;
  width: 97.89473684%;
}
.cb-layout3 .area > .slim {
  width: 50%;
}
.cb-layout3 .area > .slim h2,
.cb-layout3 .area > .slim .foot,
.cb-layout3 .area > .slim .part,
.cb-layout3 .area > .slim.grid table {
  margin-right: 2.10526316%;
  margin-left: 2.10526316%;
}
.cb-layout3 .area > .slim .part,
.cb-layout3 .area > .slim.grid table {
  width: 95.78947368%;
}
.cb-layout3 .area > .slim .tiny {
  width: 95.78947368%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.cb-layout3 .wide.wide {
  width: 60%;
}
.cb-layout3 .wide h2,
.cb-layout3 .wide .foot,
.cb-layout3 .wide .part {
  margin-right: 1.75438596%;
  margin-left: 1.75438596%;
}
.cb-layout3 .wide .part {
  width: 96.49122807%;
}
.cb-layout3 .slim.slim {
  width: 40%;
}
.cb-layout3 .slim.slim h2,
.cb-layout3 .slim.slim .foot,
.cb-layout3 .slim.slim .part {
  margin-right: 2.63157895%;
  margin-left: 2.63157895%;
}
.cb-layout3 .slim.slim .part {
  width: 94.73684211%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.cb-layout2 .main,
.cb-layout3 .main {
  margin-right: -0.89285714%;
  width: 84.82142857%;
}
.cb-layout1 .main {
  margin-left: -0.89285714%;
  width: 101.78571429%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #0080c5;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #0080c5;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #005f92;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.13333333em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #006fac #004e79 #004e79 #006fac;
  border-radius: 2px;
  background-color: #0080c5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  line-height: 2.2em;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #006fac #004e79 #004e79 #006fac;
  background-color: #008ad4;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #006fac #004e79 #004e79 #006fac;
  background-color: #005f92;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/*# sourceMappingURL=./screen-large.css.map */